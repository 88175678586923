
  import { Options, Vue } from 'vue-class-component';
  import { mapGetters, mapState } from 'vuex'
  import MyModal from "../modal/MyModal.vue";
  import Acc from "../leftCol/yourAccs/Acc.vue"
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  function returnFavoritesOnly(partners:any): any{
    var ret = []
    for (let el of partners) {
      if (el.is_favorite == true) {
        ret.push(el)
      }
    }
    return ret
  }

  @Options({
    name: "SendAskMoney",
    components: {
      MyModal: MyModal,
      Acc,
      Loading: Loading,
    },
    data() {
      return {
        showModalFrame1: false,
        showModalFrame2: false,
        showModalFrameAskMoney1: false,
        showModalFrameAskMoney2: false,
        showModalFrameCreditMoney1: false,
        showModalFrameCreditMoney2: false,
        showModalFrameCreditMoney3: false,
        showModalFrameCreditMoney4: false,
        warning: true,
        activeClass: 0,
        favoris: false,
        searchName:"",
        amount:0, 
        message:"",
        partners:[],
        recipientName:"",
        displayFavoritesOnly:false,
        amountAsked:0,
        linkGenerated:false,
        history:[],
        amountForCredit:0,
        urlForHyperlink:"",
        selectedCreditAccount:null,
        showCreditRefreshNotification: false,
        isLoading: false,
        searchRecipientError: false,
      }
    },

    computed: {
      myLink(): string {
        return this.$store.state.lokapi.paymentUrl.order_url
      },
      myHyperLink():string {
        return this.urlForHyperlink
      },
      globalBalCall():boolean {
        return this.$store.state.showCredit
      },
      hasActiveMoneyAccount():boolean {
        return this.$store.getters.activeVirtualAccounts.length > 0
      },
      ownCurrenciesPartners():Array<any> {
        let currencyIds = this.$store.getters.activeVirtualAccounts
          .map((a:any) => a.currencyId)
        return this.partners.filter((p:any) => {
          return currencyIds.indexOf(p.backendId) > -1
        })
      },
      ...mapGetters([
        'creditableMoneyAccounts',
      ]),
      ...mapState([
        'recipientHistory',
      ]),
    },

    methods: {

      resetCredit() :void {
        this.$store.state.showCredit = false
        this.urlForHyperlink= ""
        this.linkGenerated= false
        this.amountForCredit = 0
      },

      resetSendMoney():void{
        this.amount = 0
        this.message = ""
        this.searchName = ""
        this.activeClass = 0
      },

      async newLinkTab() {
        if (this.amountForCredit > 0) {
          if (!this.selectedCreditAccount) {
            if (this.creditableMoneyAccounts.length > 1) {
              throw new Error("Unexpected multiple creditable account found.")
            }
            this.selectedCreditAccount = this.creditableMoneyAccounts[0]
          }
          let url = await this.selectedCreditAccount._obj.getCreditUrl(this.amountForCredit)
          this.urlForHyperlink = url.order_url
        }
      },

      navigateToCreditOrder():void {
        window.open(this.urlForHyperlink, '_blank');
        this.urlForHyperlink = ""
        this.amountForCredit = 0
        this.showCreditRefreshNotification = true
      },

      closeAndRefresh(): void {
        this.showCreditRefreshNotification = false
        this.showModalFrameCreditMoney1 = false
        this.$store.dispatch("fetchAccounts")
        this.$store.dispatch("resetTransactions")
      },

      copyUrl() {
        const el = document.createElement('textarea');
        el.value = this.$store.state.lokapi.paymentUrl.order_url;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      
      },

      async genLink():Promise<void> {
        this.$store.state.lokapi.paymentUrl= null
        if (this.amountAsked > 0) {
          await this.$store.dispatch("genPaymentLink",this.amountAsked).then(() => {
            this.linkGenerated = true
          });
        }
      },

      async toggleFavorite(contact:any):Promise<void> {
        contact.toggleFavorite()
      },

      async searchRecipientsHistory() :Promise<void> {
        let h = []
        for (let i = 0; i < this.recipientHistory.length; i++) {
          var recipient
          try {
            recipient = await this.$lokapi.searchRecipients(this.recipientHistory[i])
            console.log(recipient)
            h.push(recipient[0])
          } catch (err) {
            console.log('searchRecipients() Failed', err)
          }
        }
      //this.partners = h
      },

      async searchRecipients() :Promise<void> {
        this.partners = []
        this.searchRecipientError = false
        var recipients
        try {
          this.isLoading = true
          recipients = await this.$lokapi.searchRecipients(this.searchName)
        } catch (err) {
          this.searchRecipientError = true
          console.log('searchRecipients() Failed', err)
        }
        this.isLoading = false
        this.partners = this.displayFavoritesOnly ? returnFavoritesOnly(recipients) : recipients
      },

      setRecipient(partner:any):void {
        this.$store.state.lokapi.recipient = partner
        this.recipientName = partner.name
      },

      async sendTransaction():Promise<void> {
        let recipient = this.$store.state.lokapi.recipient
        try {
          await recipient.transfer(this.amount.toString(), this.message)
        } catch (err) { // {RequestFailed, APIRequestFailed, InvalidCredentials, InvalidJson}
          if (err.message === 'User canceled the dialog box') {
            this.$toast.warning(
              `Transaction en cours annulée`,
              {
                position:
                  "top-right"
              });
            return
          }
          console.log('Payment failed:', err.message)
          throw err
        }
        this.$toast.success(
          `Paiement effectué à ${this.recipientName}`,
          {
            position:
              "top-right"
          });
        if (!recipient.is_favorite) {
          this.$Swal.fire({
            title: `Voulez vous ajouter ${this.recipientName} aux favoris ?`,
            showDenyButton: true,
            confirmButtonText: `Ajouter`,
            denyButtonText: `Plus tard`,
          }).then((result:any) => {
            if (result.isConfirmed) {
              this.toggleFavorite(recipient)
              this.$Swal.fire(`${this.recipientName} a bien été ajouté en favori`, '', 'success')
            }
          })
        }
        await this.$store.dispatch("fetchAccounts")
        await this.$store.dispatch("resetTransactions")
        this.searchName = ""
        this.partners = []
        this.amount = 0
        this.activeClass = 0
      },
      setSelectedCreditAccount(account: any): void {
        this.selectedCreditAccount = account
      },
      setFocus() {
        this.$nextTick(()=> {
          this.$refs.amountcredit.focus();
          this.$refs.amountcredit.select();

        })
      },
      setFocusSend() {
        this.$nextTick(()=> {
          this.$refs.amountSend.focus();
          this.$refs.amountSend.select();
        })
      }
    },
  })
  export default class SendAskMoney extends Vue {}
