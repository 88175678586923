
  import { Options, Vue } from 'vue-class-component';
  import { mapGetters, mapState } from 'vuex'
  import Acc from "./yourAccs/Acc.vue"
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  @Options({
    name:"YourAccs",
    props: {
      loaded: Boolean,
      
    },
    components: {
      Acc,
      Loading: Loading
    },
    computed: {
      totalAccountsLoaded(): number {
        return this.$store.state.lokapi.virtualAccountTree.length
      },
      isLoadingAccounts(): boolean {
        return this.$store.state.lokapi.accountsLoading
      },
      hasAccountsLoadingError(): boolean {
        return this.$store.state.lokapi.accountsLoadingError
      },
      ...mapGetters([
        'activeVirtualAccounts',
        'inactiveVirtualAccounts',
      ]),
    },
    methods: {
      refreshBalanceAndTransactions() {
        this.$store.dispatch('fetchAccounts')
        this.$store.dispatch('resetTransactions')
      }
    }
  })
  export default class YourAccs extends Vue {}
