
  import { Options, Vue } from 'vue-class-component';
  import TransactionPicto from "./pictos/TransactionPicto.vue"
  import QRPicto from "./pictos/QRPicto.vue"
  import moment from 'moment'

  @Options({
    name:"TransactionSubCard",
    components: {
      TransactionPicto,
      QRPicto
    },
    methods: {
      calcDays(date:string):string {
        moment.locale('fr');
        var test = moment(date).fromNow()
        return test
      }
    },
    props: {
      picto: String,
      amount: String,
      symbol: String,
      desc: String,
      date: String,
      name:String,
      unformatedDate:String
    },
  })
  export default class TransactionSubCard extends Vue {}
