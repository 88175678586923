
  import { Options, Vue } from 'vue-class-component';
  import Loading from 'vue-loading-overlay';

  @Options({
    name:"PendingCredits",
    components:{ Loading: Loading },
    data() {
      return {
        isLoading: true,
        hasLoadingError: false,
      }
    },
    mounted() {
      this.updatePendingCreditRequests()
    },
    computed: {
      pendingCreditRequests(): Array<any> {
        return this.$store.state.lokapi.pendingCreditRequests
      },
    },
    methods: {
      async validateCreditRequest (request: any): Promise<void> {
        try {
          await request.validate()
        } catch(err) {
          this.$Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Il y a eu un problème lors de la tentative de validation de la demande de crédit' + request.relatedUser,
            showConfirmButton: false,
            timer: 3000
          })
          throw err
        }
        this.$store.dispatch('fetchPendingCreditRequests')
        this.$Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'La demande de crédit de ' + request.relatedUser + ' d\'un montant de ' + request.amount + ' a été validée',
          showConfirmButton: false,
          timer: 3000
        })
      },
      async updatePendingCreditRequests() {
        this.isLoading = true
        try {
          await this.$store.dispatch('fetchPendingCreditRequests')
          this.hasLoadingError = false
        } catch (e:any) {
          console.error('Failed to fetch pending credit requests', e)
          this.hasLoadingError = true
        }
        this.isLoading = false
      }
    },
  })
  export default class Admin extends Vue {}
