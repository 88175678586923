
  import { Options, Vue } from 'vue-class-component';
  import LeftCol from "./core/LeftCol.vue";
  import RightCol from "./core/RightCol.vue";
  import SendAskMoney from "./sendAskMoney/SendAskMoney.vue";
  import router from "../router/index"


  @Options({
    name:"core",
    props: {
      msg: String,
    },
    components: {
      LeftCol,
      RightCol,
      SendAskMoney,
    },
  })
  export default class Core extends Vue {}
