
  import { Options, Vue } from 'vue-class-component';
    import Loading from 'vue-loading-overlay';

@Options({
  name:"PendingAccounts",
  components :{ Loading: Loading },
  data() {
    return {
      isLoading: false,
      hasLoadingError: false,
    }
  },
  mounted() {
    this.updatePendingAccount()
  },
  computed: {
    pendingUserAccounts(): Array<any> {
      return this.$store.state.lokapi.pendingUserAccounts
    }
  },
  methods: {
    validateUserAccount(account: any):void {
      account.validateCreation()
        .catch((err: any) => {
          this.$Swal.fire({
            position: 'top',
            icon: 'error',
            title: 'Il y a eu un problème lors de la tentative de validation de l\'utilisateur ' + account.name,
            showConfirmButton: false,
            timer: 3000
          })
        }).then((result: any) => {
          this.updatePendingAccount()
          this.$Swal.fire({
            position: 'top',
            icon: 'success',
            title: 'Le compte de l\'utilisateur ' + account.name + ' a été validé',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    async updatePendingAccount(){
      this.isLoading = true
      try {
        await this.$store.dispatch('fetchPendingUserAccounts')
        this.hasLoadingError = false
      } catch (e:any) {
        console.error('Failed to fetch pending accounts', e)
        this.hasLoadingError = true
      }
      this.isLoading = false
    }
  },
})
export default class Admin extends Vue {}
