import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransactionSubCard = _resolveComponent("TransactionSubCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTrs, (transaction) => {
      return (_openBlock(), _createBlock(_component_TransactionSubCard, {
        key: transaction,
        amount: transaction.amount,
        symbol: transaction.currency,
        desc: transaction.description,
        date: _ctx.dateFormated(transaction.date),
        unformatedDate: transaction.date,
        name: transaction.relatedUser ? transaction.relatedUser.display : transaction.related.type.name ,
        picto: "QR"
      }, null, 8, ["amount", "symbol", "desc", "date", "unformatedDate", "name"]))
    }), 128))
  ]))
}