
  import { Options, Vue } from 'vue-class-component';
  import router from "@/router/index"
  import { LokAPIExc } from "@/services/lokapiService"

  @Options({
    name:"CreateAccount",
    mounted() {
      if (this.unconfiguredBackends.length === 0) {
        router.push('/')
      }
      if (this.unconfiguredBackends.length === 1) {
        this.form.accountBackend = this.unconfiguredBackends[0]
      }
    },
    data() {
      return {
        form: {
          accountBackend: '',
          accountPassword: '',
          accountPasswordConfirm: '',
          errors: {
            accountPassword: [],
            accountPasswordConfirm: []
          }
        }
      }
    },
    watch: {
      unconfiguredBackends(newval, oldval): void {
        if (newval.length === 1) {
          this.form.accountBackend = newval[0]
        }
      },
      "form.accountPassword": function (): void {
        this.checkPasswordField('accountPassword')
        this.checkIsSame('accountPasswordConfirm', 'accountPassword')
      },
      "form.accountPasswordConfirm": function (): void {
        this.checkIsSame('accountPasswordConfirm', 'accountPassword')
      }
    },
    computed: {
      unconfiguredBackends(): object {
        return this.$store.getters.getUnconfiguredBackends()
      },
      hasFieldErrors(): boolean {
        if (this.form.accountPassword.length === 0 || this.form.accountPasswordConfirm.length === 0)
          return true
        return Object.keys(this.form.errors).filter(field => {
          return this.form.errors[field].length > 0
        }).length > 0
      }
    },
    methods: {
      async checkPasswordField(fieldname: string, accountBackend: string) {
        this.form.errors[fieldname] = await this.$store.dispatch(
          "checkPasswordStrength", [this.form[fieldname], this.form['accountBackend']])
      },
      checkIsSame(fieldOne:string, fieldTwo:string): void {
        if (this.form[fieldOne] !== this.form[fieldTwo]) {
          this.form.errors[fieldOne] = ["Le mot de passe entré n'est pas identique"]
        } else {
          this.form.errors[fieldOne] = []
        }
      },
      hasError(field: string): boolean {
        return this.form.errors[field].length > 0
      },
      async createUserAccount() {
        try {
          await this.$store.dispatch(
            "createUserAccount", [this.form['accountPassword'], this.form['accountBackend']])
        } catch(err) {
          console.error("Something went wrong on createUserAccount request", err)
          if (!(err instanceof LokAPIExc.UserAccountAlreadyExists)) {
            this.$toast.error(
              "Création de compte interrompue inopinément." +
                "Veuillez ré-éssayer ou contacter votre administrateur",
              { position: "top" }
            )
            return // stay on page
          }
          this.$toast.warning("Compte déjà créé")
        }
        router.push({ name: "dashboard" })
      }
    },
    props: {
    },
    components: {},
  })
  export default class CreateAccount extends Vue {}
