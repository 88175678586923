
  import { Options, Vue } from "vue-class-component";

  @Options({
    name: "Nav",
    data() {
      return {
        showNav: false,
        isMenuClosed: false
      };
    },
    methods: {
      logout() {
        this.$store.dispatch("askLogOut");
        this.$router.push({ name: "Login" });
      },
    },
    computed: {
      getLog(): string {
        return this.$store.state.lokapi.isLog;
      },
      hasUnconfiguredBackend(): boolean {
        // Display of the account creation button should be displayed only
        // if there's an un-configured backend
        return this.$store.getters.hasUnconfiguredBackend();
      },
      hasUserAccountValidationRights(): boolean {
        return this.$store.state.lokapi.hasUserAccountValidationRights;
      },
      hasCreditRequestValidationRights(): boolean {
        return this.$store.state.lokapi.hasCreditRequestValidationRights
      },
      userProfile(): string {
        return this.$store.state.lokapi.userProfile;
      },
      profilePageUrl(): string {
        return this.$store.getters.getOdooUrl() + "/web/login";
      },
      hasMapUrl(): string {
        return this.$config.mapUrl
      },
      helpUrl(): string {
        return this.$config.helpUrl
      },
      cguUrl(): string {
        return this.$config.cguUrl
      },
    },
  })
  export default class Nav extends Vue {}
