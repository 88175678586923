
  import { Options, Vue } from "vue-class-component";

  @Options({
    name: "Carto",
    data() {
      return {
        mapUrl: "",
      };
    },
    mounted() {
      this.mapUrl = this.$config.mapUrl
      if (!this.mapUrl) {
        console.error("This view require a valid `mapUrl` configured in `config.json`")
      }
    },
  })
  export default class Carto extends Vue {}
