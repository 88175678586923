/* eslint-disable */

///<reference types="@types/node"/>

import { createStore } from 'vuex'


export default createStore({
  state: {
    showCredit:false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
})
