
  import { Options, Vue } from 'vue-class-component';
  import TransactionSubCard from "./TransactionSubCard.vue"
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  @Options({
    name:"ThisWeek",
    components: {
      TransactionSubCard,
      Loading: Loading
    },
    data() {
      return {}
    },
    computed: {
      getTrs(): any {
        return this.$store.state.lokapi.thisWeektransactions
      },
      isLoadingTransactions(): boolean {
        return this.$store.state.lokapi.transactionsLoading
      },
      hasTransactionsLoadingError(): boolean {
        return this.$store.state.lokapi.transactionsLoadingError
      },
    },
   
    methods : { 
      dateFormated(badDate:string) :string {
        var date = new Date(badDate);
        var options = {weekday: "long", day: "numeric", month: "numeric"};
        const DateFr = new Intl.DateTimeFormat('fr-FR', options).format(date)
        return DateFr
      }
    },
  })
  export default class ThisWeek extends Vue {}
