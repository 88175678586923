
  import { Options, Vue } from 'vue-class-component';
  import ThisWeek from "../rightCol/ThisWeek.vue";
  import AllTrs from "../rightCol/AllTrs.vue";

  @Options({
    name: "RightCol",
    components: {
      ThisWeek,
      AllTrs,
    },

    data() {
      return {
        showModal: false
      };
    },
    computed: {
      getTrs(): any {
        return this.$store.state.lokapi.thisWeektransactions
      },
      isLoadingTransactions(): boolean {
        return this.$store.state.lokapi.transactionsLoading
      }
    },
    watch: {
      showModal(newval: boolean, oldval: boolean) {
        if (newval) {
          this.$nextTick(() => {
            let div = this.$refs.transactionsContainer
            if (div.scrollTop === (div.scrollHeight - div.offsetHeight)) {
              this.$store.dispatch('fetchTransactionsBatch')
            }
          })
        }
      }
    },
    methods: {
      handleScroll(evt: any) {
        if (evt.target.scrollTop === (evt.target.scrollHeight - evt.target.offsetHeight)) {
          this.$store.dispatch('fetchTransactionsBatch')
        }
      },
    }
  })
  export default class RightCol extends Vue {}
