
  import { Options, Vue } from 'vue-class-component';

  @Options({
    name:"Acc",
    props: {
      name:String,
      bal:Number,
      curr:String,
      backend:String,
      type:String,
      active:Boolean,
      subAccounts: Array,
      isSub: Boolean,
    },
    computed: {
      isMultiCurrency(): any {
        return this.$store.state.lokapi.isMultiCurrency
      },
    }
  })
  export default class Acc extends Vue {}
