import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "container mt-5" }
const _hoisted_3 = { class: "columns is-tablet" }
const _hoisted_4 = { class: "action-footer-container" }
const _hoisted_5 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftCol = _resolveComponent("LeftCol")!
  const _component_RightCol = _resolveComponent("RightCol")!
  const _component_SendAskMoney = _resolveComponent("SendAskMoney")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LeftCol),
        _createVNode(_component_RightCol)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SendAskMoney)
      ])
    ])
  ]))
}